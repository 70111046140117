import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
// import Img from 'gatsby-image'
import typography from '../../utils/typography'
import { GoogleFont } from 'react-typography'

import { Navigation } from '.'
// import config from '../../utils/siteConfig'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Styles
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../styles/banana.css'

// Images
import favicon from "../../images/btg-favicon-1.png"
import btg_logo_1 from "../../images/btg-logo-1.png"

// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null

    return (
        <>
            <Helmet>
                <GoogleFont typography={typography} />
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>

            <Navigation navLinks={site.navigation} />

            {isHome ?
                <Container fluid={true} className="header-banner">
                    <Row>
                        <Col xs={1}></Col>
                        <Col style={{ textAlign: `center` }}>
                            <img src={btg_logo_1} style={{ width: `50%`, minWidth: `20rem` }} />
                            <br />
                            <br />
                            <p className="site-banner-desc">{site.description}</p>
                            <p className="header-banner-social">
                                <a href="https://www.youtube.com/channel/UCwBMoDXp7c7MA2lIK2GXXBw/" rel="noreferrer" target="_blank">
                                    <FontAwesomeIcon icon={['fab', 'youtube']} />
                                </a>
                                {/* <a href="https://twitter.com/@banatasty" target="_blank">
                                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                                </a> */}
                            </p>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
                </Container> :
                <Container>
                    <Row>
                        <Col>
                            <Link to="/" style={{ display: `inherit`, padding: `20px`, color: `var(--color-primary)`, textAlign: `center` }}>
                                <span><FontAwesomeIcon icon={faHome} /> Home</span>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            }

            <Container fluid={true}>
                <Row>
                    <Col className="body-content">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </Col>
                </Row>
            </Container>

            <Container fluid={true}>
                <Row>
                    <Col style={{ padding: `0px` }}>
                        {/* The footer at the very bottom of the screen */}
                        <footer className="site-foot">
                            <div className="site-foot-nav container">
                                <div className="site-foot-nav-left">
                                    <Link to="/">{site.title}</Link> &copy; 2020
                                </div>
                                <div className="site-foot-nav-right">
                                    <Navigation navLinks={site.navigation} includeBrand={false} />
                                </div>
                            </div>
                        </footer>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "btg-icon-1.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
