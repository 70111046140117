import Typography from "typography"

const typography = new Typography({
    headerFontFamily: ['Pangolin', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif'],
    bodyFontFamily: ['Pangolin', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", 'sans-serif'],
    googleFonts: [
        {
            name: 'Pangolin',
            styles: [
                '400'
            ]
        },
        {
            name: 'Patrick Hand',
            styles: [
                '400'
            ]
        }
    ]
})

// Export helper functions
export const { scale, rhythm, options } = typography
export default typography
