import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import React from 'react'
import PropTypes from 'prop-types'

// Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// Images
import btg_icon_sm_1 from "../../images/btg-icon-sm-1.png"

const Navigation = ({ navLinks, includeBrand }) => (
    <>
        <Container fluid={true} className="header-nav">
            <Row>
                <Col>
                    <Navbar collapseOnSelect expand="lg">
                        {includeBrand &&
                            <Navbar.Brand href="/">
                                <img className="header-nav-icon" src={btg_icon_sm_1} alt="Banana Tasty Icon" />
                                <img className="header-nav-icon" src={btg_icon_sm_1} alt="Banana Tasty Icon" />
                                <img className="header-nav-icon" src={btg_icon_sm_1} alt="Banana Tasty Icon" />
                            </Navbar.Brand>
                        }
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" title="Menu">
                            <Nav className="mr-auto">
                            </Nav>
                            <Nav>
                                {navLinks.map((navItem, i) => {
                                    if (navItem.url.match(/^\s?http(s?)/gi)) {
                                        return <a className="nav-link" href={navItem.url} key={i} target="_blank" rel="noopener noreferrer">{navItem.label}</a>
                                    } else {
                                        return <Nav.Link href={navItem.url} key={i} eventKey={i}>{navItem.label}</Nav.Link>
                                    }
                                })}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
            </Row>
        </Container>
    </>
)

Navigation.propTypes = {
    navLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    includeBrand: PropTypes.bool,
}

Navigation.defaultProps = {
    includeBrand: true,
}

export default Navigation
